import cancel from "../img/svg/cancel.svg";

export default function Modalbox() {
    const noLink = "#";
    return (
        <div className="iknow_tm_modalbox">
            <div className="box_inner">
                <div className="close">
                    <a href={noLink}><img className="svg" src={cancel} alt=""/></a>
                </div>
                <div className="description_wrap"/>
            </div>
        </div>
    );
}