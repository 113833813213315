import image from "../img/thumbs/35-44.jpg";
import image2 from "../img/about/aaron1.png";
import {useTranslation} from "react-i18next";

export default function About() {
    const {t} = useTranslation();
    return(
        <div id="about" className="iknow_tm_main_section">
            <div className="iknow_tm_about">
                <div className="left">
                    <div className="left_inner">
                        <div className="image">
                            <img src={image} alt=""/>
                            <div className="main" data-img-url={image2} />
                        </div>
                        <div className="details">
                            <ul>
                                <li>
                                    <h3>{t("about.name_title")}</h3>
                                    <span>{t("about.name")}</span>
                                </li>
                                <li>
                                    <h3>{t("about.birthday_title")}</h3>
                                    <span>{t("about.birthday")}</span>
                                </li>
                                <li>
                                    <h3>{t("about.email_title")}</h3>
                                    <span>{t("about.email")}</span>
                                </li>
                                <li>
                                    <h3>{t("about.city_title")}</h3>
                                    <span>{t("about.city")}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="right">
                    <div className="iknow_tm_main_title">
                        <span>{t("about.title")}</span>
                    </div>
                    <div className="bigger_text"> <p>{t("about.description_p1")}</p></div>
                    <div className="text">

                        <p>{t("about.description_p2")}</p>
                        <p>{t("about.description_p4")}</p>
                    </div>
                    <div className="iknow_tm_button">

                    </div>
                </div>
            </div>
        </div>
    );
}