import {useTranslation} from "react-i18next";

export default function Resume() {
    const {t} = useTranslation();
    return (
        <div id="resume" className="iknow_tm_main_section">
            <div className="iknow_tm_resume">
                <div className="iknow_tm_main_title">
                    <h3>{t("resume.title_alt")}</h3>
                </div>
                <div className="resume_inner">
                    <div className="left">
                        <div className="info_list">
                            <div className="iknow_tm_resume_title">
                                <h3>{t("resume.education")}</h3>
                                <span className="shape" />
                            </div>
                            <ul>
                                <li>
                                    <div className="list_inner">
                                        <div className="short">
                                            <div className="job">
                                                <h3>{t("resume.education_items.cs_degree.title")}</h3>
                                                <span>Universitat Politècnica de Catalunya</span>
                                            </div>
                                            <div className="year">
                                                <span>2018 - 2022</span>
                                            </div>
                                        </div>
                                        <div className="text">
                                            <p>{t("resume.education_items.cs_degree.description")}</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="list_inner">
                                        <div className="short">
                                            <div className="job">
                                                <h3>{t("resume.education_items.tech_degree.title")}</h3>
                                                <span>Espai Escola d'Informàtica</span>
                                            </div>
                                            <div className="year">
                                                <span>2017 - 2018</span>
                                            </div>
                                        </div>
                                        <div className="text">
                                            <p>{t("resume.education_items.tech_degree.description")}</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="list_inner">
                                        <div className="short">
                                            <div className="job">
                                                <h3>{t("resume.education_items.agora.title")}</h3>
                                                <span>Agora Sant Cugat Int. School</span>
                                            </div>
                                            <div className="year">
                                                <span>2015 - 2017</span>
                                            </div>
                                        </div>
                                        <div className="text">
                                            <p>{t("resume.education_items.agora.description")}</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                    </div>
                    <div className="right">
                        <div className="info_list">
                            <div className="iknow_tm_resume_title">
                                <h3>{t("resume.experience")}</h3>
                                <span className="shape"/>
                            </div>
                            <ul>
                                <li>
                                    <div className="list_inner">
                                        <div className="short">
                                            <div className="job">
                                                <h3>{t("resume.experience_items.mido.title")}</h3>
                                                <span>{t("resume.experience_items.mido.company")}</span>
                                            </div>
                                            <div className="year">
                                                <span>2021 - {t("resume.running")}</span>
                                            </div>
                                        </div>
                                        <div className="text">
                                            <p>{t("resume.experience_items.mido.desc")}</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="list_inner">
                                        <div className="short">
                                            <div className="job">
                                                <h3>{t("resume.experience_items.conper.title")}</h3>
                                                <span>{t("resume.experience_items.conper.company")}</span>
                                            </div>
                                            <div className="year">
                                                <span>2018 - {t("resume.running")}</span>
                                            </div>
                                        </div>
                                        <div className="text">
                                            <p>{t("resume.experience_items.conper.desc")}</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="list_inner">
                                        <div className="short">
                                            <div className="job">
                                                <h3>{t("resume.experience_items.eiil.title")}</h3>
                                                <span>{t("resume.experience_items.eiil.company")}</span>
                                            </div>
                                            <div className="year">
                                                <span>2015 - {t("resume.running")}</span>
                                            </div>
                                        </div>
                                        <div className="text">
                                            <p>{t("resume.experience_items.eiil.desc")}</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}