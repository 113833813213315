import React from 'react';
import ReactDOM from 'react-dom';
import Jquery from "jquery";
import WOW from "wow.js";
import ReactGA from 'react-ga';

import {
    dodo_parallax_animation, hashtag, hashtag2,
    iknow_tm_circular_progress,
    iknow_tm_content_height,
    iknow_tm_cursor,
    iknow_tm_data_images,
    iknow_tm_down,
    iknow_tm_extra_menu,
    iknow_tm_hero_height,
    iknow_tm_imgtosvg, iknow_tm_menu_image_animation,
    iknow_tm_menu_width, iknow_tm_menu_width_new,
    iknow_tm_menubar,
    iknow_tm_news_popup,
    iknow_tm_page_transition,
    iknow_tm_popup,
    iknow_tm_portfolio,
    iknow_tm_portfolio_popup, iknow_tm_scrollable,
    iknow_tm_service_popup, iknow_tm_trigger_menu,
    iknow_tm_trigger_opener,
    iknow_tm_vanta_effects
} from "./system/init";
import Web from "./Web";
import "./i18n";
import {Suspense} from "react";

const TRACKING_ID = "G-M371G2VSLC";
ReactGA.initialize(TRACKING_ID);


ReactDOM.render(<Suspense fallback={<h1>Loading content...</h1>}>
    <Web />
    </Suspense>,
  document.getElementById('root')
);

const wow = new WOW();
wow.init();

Jquery(document).ready(function (){
    iknow_tm_down();
    iknow_tm_content_height();
    iknow_tm_menu_width();
    iknow_tm_portfolio();
    iknow_tm_portfolio_popup();
    iknow_tm_service_popup();
    iknow_tm_news_popup();
    iknow_tm_cursor();
    iknow_tm_imgtosvg();
    iknow_tm_popup();
    iknow_tm_data_images();
    iknow_tm_menubar();
    iknow_tm_hero_height();
    iknow_tm_page_transition();
    dodo_parallax_animation();
    if(Jquery('.iknow_tm_hero .main_menu, .iknow_tm_fixed_content .menubar').length ){
        iknow_tm_circular_progress();
    }

    iknow_tm_extra_menu();
    iknow_tm_vanta_effects();
    iknow_tm_trigger_opener();
    iknow_tm_trigger_menu();
    iknow_tm_menu_width_new();
    hashtag();
    hashtag2();
    iknow_tm_scrollable();
    iknow_tm_menu_image_animation();

    Jquery(window).on('resize', function(){
        iknow_tm_hero_height();
    });
})








