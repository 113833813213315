import Preloader from "./elements/preloader";
import Modalbox from "./elements/modalbox";
import Topbar from "./elements/topbar";
import Hero from "./elements/hero";
import ExtraMenu from "./elements/extra_menu";
import About from "./sections/About";
import Resume from "./sections/Resume";
import Portfolio from "./sections/Portfolio";
import Contact from "./sections/Contact";
import Copyright from "./sections/Copyright";
import React from "react";
import {iknow_tm_my_load} from "./system/init";

export default class Web extends React.Component {

    componentDidMount() {
        iknow_tm_my_load();
    }
    render() {
        return (<div>
            <Preloader />
            <div className="iknow_tm_all_wrap" data-magic-cursor="hide">
                <Modalbox />
                <Topbar />
                <Hero />

                <ExtraMenu />
                <div className="container">
                    <About />
                    <Resume />
                    <Portfolio />
                    <Contact />
                </div>
                <Copyright />
                <div className="mouse-cursor cursor-outer"/>
                <div className="mouse-cursor cursor-inner"/>
            </div>
        </div>)
    }
}
