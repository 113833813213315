import archive from "../img/svg/archive.svg";
import down from "../img/svg/down.svg";
import contact from "../img/svg/contacs.svg";
import briefcase from "../img/svg/briefcase.svg";
import letter from "../img/svg/letter.svg";
import {useTranslation} from "react-i18next";

export default function ExtraMenu() {
    const about = "#about";
    const resume = "#resume";
    const portfolio = "#portfolio";
    const contactM = "#contact";
    const {t} = useTranslation();
    return(
        <div className="iknow_tm_extra_menu">
            <div className="container">
                <div className="menu_list">
                    <ul className="unordered">
                        <li className="unorderest_li">
                            <div className="list_in">
                                <div className="list_icon">
                                    <img className="svg" src={archive} alt=""/>
                                </div>
                                <div className="center">
                                    <span>{t("about.title")}</span>
                                </div>
                                <div className="down_icon">
                                    <img className="svg" src={down} alt=""/>
                                </div>
                                <a className="iknow_tm_full_link" href={about} > </a>
                            </div>
                        </li>
                        <li className="unorderest_li">
                            <div className="list_in">
                                <div className="list_icon">
                                    <img className="svg" src={contact} alt=""/>
                                </div>
                                <div className="center">
                                    <span>{t("resume.title")}</span>
                                </div>
                                <div className="down_icon">
                                    <img className="svg" src={down} alt=""/>
                                </div>
                                <a className="iknow_tm_full_link" href={resume} > </a>
                            </div>
                        </li>
                        <li className="unorderest_li">
                            <div className="list_in">
                                <div className="list_icon">
                                    <img className="svg" src={briefcase} alt=""/>
                                </div>
                                <div className="center">
                                    <span>{t("projects.title")}</span>
                                </div>
                                <div className="down_icon">
                                    <img className="svg" src={down} alt=""/>
                                </div>
                                <a className="iknow_tm_full_link" href={portfolio}> </a>
                            </div>
                        </li>
                        <li className="unorderest_li">
                            <div className="list_in">
                                <div className="list_icon">
                                    <img className="svg" src={letter} alt=""/>
                                </div>
                                <div className="center">
                                    <span>{t("contact.title")}</span>
                                </div>
                                <div className="down_icon">
                                    <img className="svg" src={down} alt=""/>
                                </div>
                                <a className="iknow_tm_full_link" href={contactM}> </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}