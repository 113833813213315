import {useTranslation} from "react-i18next";

export default function Copyright() {
    const {t} = useTranslation();
    return(
        <div className="iknow_tm_copyright">
            <div className="container">
                <div className="inner">
                    <div className="left">
                        <p>{t("footer.designed_with_love")}</p>
                    </div>
                    <div className="right">
                        <ul>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}