import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from "./locales/en-US/translation.json";
import es from "./locales/es-ES/translation.json";

const resources = {
    "en-US": {
        translation: en
    },
    "es-ES": {
        translation: es
    }
}

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en-US',
        debug: true,
        interpolation: {
            escapeValue: false,
        }
    });

export default i18n;