import React from "react";
import one from "../img/thumbs/1-1.jpg";
import text from "../img/svg/text.svg";
import facebook from "../img/svg/social/github.svg";
import web from "../img/svg/web.svg";
import q2 from "../img/thumbs/4-2.jpg";

export default class Project extends React.Component {

    render() {
        const noLink = "#";
        return(
            <li className="detail">
                <div className="list_inner">
                    <div className="image">
                        <img src={one} alt=""/>
                        <div className="main" data-img-url={this.props.pic1}/>
                    </div>
                    <div className="overlay"/>
                    <img className="svg" src={text} alt=""/>
                    <div className="details">
                        <h3>{this.props.name}</h3>
                    </div>
                    <a className="iknow_tm_full_link portfolio_popup" href={noLink}> </a>

                    <div className="hidden_content">
                        <div className="popup_details">
                            <div className="main_details">
                                <div className="textbox">
                                    <p>{this.props.description}</p>
                                </div>
                                <div className="detailbox">
                                    <ul>
                                        <li>
                                            <span className="first">Client</span>
                                            <span>{this.props.client}</span>
                                        </li>
                                        <li>
                                            <span className="first">Date</span>
                                            <span>{this.props.date}</span>
                                        </li>
                                        <li>
                                            <span className="first">Github:</span>
                                            <ul className="share">
                                                <li><a href={this.props.github} target={"_blank"} rel={"noreferrer"}><img className="svg"
                                                                          src={facebook}
                                                                          alt=""/></a></li>
                                            </ul>
                                        </li>
                                        <li>
                                            <span className="first">Demo:</span>
                                            <ul className="share">
                                                <li><a href={this.props.demo} target={"_blank"} rel={"noreferrer"}><img className="svg"
                                                                          src={web}
                                                                          alt=""/></a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="additional_images">
                                <ul>
                                    <li>
                                        <div className="list_inner">
                                            <div className="my_image">
                                                <img src={q2} alt=""/>
                                                <div className="main"
                                                     data-img-url={this.props.pic1}/>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="list_inner">
                                            <div className="my_image">
                                                <img src={q2} alt=""/>
                                                <div className="main"
                                                     data-img-url={this.props.pic2}/>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="list_inner">
                                            <div className="my_image">
                                                <img src={q2} alt=""/>
                                                <div className="main"
                                                     data-img-url={this.props.pic3}/>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </li>
        );
    }
}