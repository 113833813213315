import letter from "../img/svg/letter.svg";
import {useTranslation} from "react-i18next";

export default function Contact() {
    const noLink = "#";
    const {t} = useTranslation();
    return(
        <div id="contact" className="iknow_tm_main_section">
            <div className="iknow_tm_contact">
                <div className="iknow_tm_main_title">
                    <h3>{t("contact.title_alt")}</h3>
                </div>
                <div className="wrapper">
                    <div className="left">
                        <ul>
                            <li>
                                <div className="list_inner">
                                            <span className="icon"><img className="svg" src={letter}
                                                                        alt=""/></span>
                                    <div className="short">
                                        <span><a href={noLink}>aaron@mbotton.com</a></span>
                                    </div>
                                </div>

                            </li>

                        </ul>
                    </div>
                    <div className="right">

                    </div>
                </div>
            </div>
        </div>
    );
}