import gitubimage from "../img/svg/social/github.svg";
import linkedin2 from "../img/svg/social/linkedin-2.svg";
import instagram from "../img/svg/social/instagram.svg";
import hero2 from "../img/hero/aaron2.png";

import shape10 from "../img/svg/shapes/10.svg";
import shape11 from "../img/svg/shapes/11.svg";
import shape6 from "../img/svg/shapes/6.svg";
import {useTranslation} from "react-i18next";

export default function Hero() {
    const {t} = useTranslation();
    const linkedinLink = "https://www.linkedin.com/in/aaronmoralesb";
    const github = "https://github.com/aaronmbdev";
    const instaLink = "https://www.instagram.com/aaron.morales.b/";
    return (
        <div className="iknow_tm_hero_second">
            <div className="background_shape"/>
            <div className="container">
                <div className="main_info">
                    <div className="left">
                        <span className="subtitle">{t('common.im')}</span>
                        <h3 className="name">{t("common.name")}</h3>
                        <p className="text">{t("common.position")}</p>
                        <div className="social">
                            <ul>
                                <li><a href={github} rel={"noreferrer"} target={"_blank"}><img className="svg" src={gitubimage} alt=""/></a></li>
                                <li><a href={linkedinLink} rel={"noreferrer"} target={"_blank"}><img className="svg" src={linkedin2} alt=""/></a></li>
                                <li><a href={instaLink} rel={"noreferrer"} target={"_blank"}><img className="svg" src={instagram} alt=""/></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="right">
                        <div className="image">
                            <div className="main" data-img-url={hero2} />
                            <span className="first anim_translate"/>
                            <span className="second anim_left2"/>
                            <span className="third anim_right2"/>
                        </div>
                    </div>
                </div>
            </div>


            <div className="rounded_shapes">
                <span className="one anim_circle"><img className="svg" src={shape11} alt=""/></span>
                <span className="two anim_scale2"><img className="svg" src={shape10} alt=""/></span>
                <span className="three anim_scale2"><img className="svg" src={shape6} alt=""/></span>
                <span className="four anim_scale2"><img className="svg" src={shape6} alt=""/></span>
                <span className="five anim_circle"><img className="svg" src={shape11} alt=""/></span>
                <span className="six anim_scale2"><img className="svg" src={shape10} alt=""/></span>
                <span className="seven anim_scale2"><img className="svg" src={shape6} alt=""/></span>
                <span className="eight anim_scale2"><img className="svg" src={shape10} alt=""/></span>
                <span className="nine anim_scale2"><img className="svg" src={shape6} alt=""/></span>
                <span className="ten anim_scale2"><img className="svg" src={shape10} alt=""/></span>
                <span className="eleven anim_circle"><img className="svg" src={shape11} alt=""/></span>
                <span className="twelve anim_circle"><img className="svg" src={shape11} alt=""/></span>
                <span className="thirteen anim_scale2"><img className="svg" src={shape6} alt=""/></span>
                <span className="fourteen anim_circle"><img className="svg" src={shape11} alt=""/></span>
                <span className="fifteen anim_scale2"><img className="svg" src={shape6} alt=""/></span>
                <span className="sixteen anim_scale2"><img className="svg" src={shape10} alt=""/></span>
                <span className="seventeen anim_scale2"><img className="svg" src={shape10} alt=""/></span>
                <span className="eighteen anim_scale2"><img className="svg" src={shape10} alt=""/></span>
            </div>


        </div>
    );
}