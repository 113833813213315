import Project from "../elements/project";
import queue1 from "../img/portfolio/queue1.png";
import queue2 from "../img/portfolio/queue2.png";
import queue3 from "../img/portfolio/queue3.png";
import votacion1 from "../img/portfolio/votacion1.png";
import votacion2 from "../img/portfolio/votacion2.png";
import votacion3 from "../img/portfolio/votacion3.png";
import tablets from "../img/portfolio/tablets.png";
import {useTranslation} from "react-i18next";

export default function Portfolio() {
    const {t} = useTranslation();
    return(
        <div id="portfolio" className="iknow_tm_main_section">
            <div className="iknow_tm_portfolio">
                <div className="iknow_tm_main_title">
                    <span>{t("projects.title_alt")}</span>
                    <h3> </h3>
                </div>
                <div className="portfolio_list">
                    <ul className="gallery_zoom">
                        <Project
                            name={t("projects.project_items.voting.title")}
                            description={t("projects.project_items.voting.description")}
                            client={t("projects.clients.conper")}
                            date={"2021"}
                            github={"https://github.com/aaronmbdev/elecciones2021-consultamesa"}
                            demo={"https://consultamesabcn.netlify.app/"}
                            pic1={votacion1}
                            pic2={votacion2}
                            pic3={votacion3}
                        />

                        <Project
                            name={t("projects.project_items.tablets.title")}
                            description={t("projects.project_items.tablets.description")}
                            client={t("projects.clients.conper")}
                            date={"2021"}
                            github={"https://github.com/aaronmbdev/elecciones2021-gestionTablets"}
                            demo={"https://gestiontablets2021.netlify.app/"}
                            pic1={tablets}
                        />

                        <Project
                            name={t("projects.project_items.colas.title")}
                            description={t("projects.project_items.colas.description")}
                            client={t("projects.clients.conper")}
                            date={"2018"}
                            github={"#"}
                            demo={"#"}
                            pic1={queue1}
                            pic2={queue2}
                            pic3={queue3}
                        />

                    </ul>
                </div>
            </div>
        </div>
    );
}